type AuthorizedUserAdaApiResponse = {
  articulateId?: string;
  contactCompany?: string;
  contactEmail?: string;
  contactFirstName?: string;
  contactLastName?: string;
  error?: string;
};

const timeoutPromise = () =>
  new Promise<never>((_, reject) =>
    setTimeout(reject, 5000, "Timed out after 5 seconds")
  );

async function authorizeUserAda() {
  const controller = new AbortController();
  const { signal } = controller;

  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  document.body.appendChild(iframe);

  const getUserAid = () =>
    new Promise<AuthorizedUserAdaApiResponse>((resolve, reject) => {
      window.addEventListener(
        "message",
        (event: MessageEvent<AuthorizedUserAdaApiResponse>) => {
          if (event.origin === window.AUTH_SERVICE_URI) {
            const {
              data: {
                articulateId,
                contactFirstName,
                contactLastName,
                contactEmail,
                contactCompany,
                error,
              } = {},
            } = event;

            if (error) {
              reject(new Error(error));
            } else {
              resolve({
                articulateId,
                contactCompany,
                contactEmail,
                contactFirstName,
                contactLastName,
              });
            }
          }
        },
        { signal }
      );

      iframe.addEventListener("error", (error) => {
        controller.abort();
        reject(error.error);
      });

      const url = new URL("/api/ada/users/me", window.AUTH_SERVICE_URI);
      url.searchParams.set("response_mode", "post_message");
      url.searchParams.set("target_origin", window.location.origin);

      iframe.src = url.toString();
    });

  try {
    const {
      articulateId,
      contactCompany,
      contactEmail,
      contactFirstName,
      contactLastName,
    } = await Promise.race([getUserAid(), timeoutPromise()]);

    // Ada's plugin is fully loaded
    if (window?.adaEmbed?.setMetaFields) {
      await window.adaEmbed.setMetaFields({
        articulateId,
        contactCompany,
        contactEmail,
        contactFirstName,
        contactLastName,
      });
    } else {
      window.adaSettings = {
        metaFields: {
          articulateId,
          contactCompany,
          contactEmail,
          contactFirstName,
          contactLastName,
        },
      };
    }
  } catch (err) {
    console.log("Failed to retrieve current user`s AID for Ada");
    controller.abort();
  }

  document.body.removeChild(iframe);
}

window.addEventListener(
  "load",
  () => {
    void authorizeUserAda();
  },
  { once: true }
);
